import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"

const DPA = () => {
  return (
    <Layout>
      <div
        className="techical_perspective-section"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div style={{ maxWidth: "1024px", padding: "0 20px" }}>
          <h1
            style={{ textAlign: "center", fontWeight: "600", fontSize: "24px" }}
          >
            Data Processing Addendum
          </h1>
          <div class="page-body">
            <p>
              <strong>Overview</strong>
            </p>
            <p>
              This Data Processing Addendum (&quot;Addendum&quot;), effective as
              of the DPA Effective Date (defined below), is entered into by and
              between Cubo, Inc. (&quot;Company&quot;) and you
              (&quot;Customer&quot;) (collectively the &quot;Parties&quot;).
              This Addendum (a) forms part of the Terms of Service along with
              other agreements you may have entered with Company governing the
              provision of Company&#x27;s Cubo application and any other related
              services (collectively the &quot;Services&quot;) provided to you
              by Company (this &quot;Agreement&quot;), (b) constitutes the
              agreement governing the processing of Customer data by Company,
              and (c) shall not be construed to impose any obligations beyond
              those required by the GDPR.
            </p>
            <ol
              type="1"
              id="51e3cf72-a955-466d-990c-b03f801d9dec"
              class="numbered-list"
              start="1"
            >
              <li>
                <strong>General</strong>
              </li>
            </ol>
            <ol
              type="1"
              id="0aadb967-fe01-4388-80cf-7ce4ff8a57e6"
              class="numbered-list"
              start="2"
            >
              <li>
                a) Agreement to Terms. If you are accessing and using the
                Services on behalf of a company (such as your employer) or other
                legal entity, you represent and warrant that you are lawfully
                able to enter into contracts and that you have the authority to
                bind such entity to this Addendum. In that case,
                &quot;Customer&quot; will refer to that company or other legal
                entity.
              </li>
            </ol>
            <ol
              type="1"
              id="d01a231f-3814-4187-972a-2870f1c72f2f"
              class="numbered-list"
              start="3"
            >
              <li>
                b) Subject Matter. This Addendum reflects the commitment of
                Company and Customer to abide by Applicable Data Protection Laws
                concerning the Processing of Customer Personal Data in
                connection with the Services. Customer will be the Controller or
                Processor of Customer Personal Data and Company will be the
                Processor of Customer Personal Data under Applicable Data
                Protection Law(s). All capitalized terms that are not expressly
                defined in this Data Processing Addendum will have the meanings
                given to them in the Agreement. Except as expressly provided
                herein, nothing in this Addendum shall be deemed to waive or
                modify any of the provisions of the Agreement, which otherwise
                remains in full force and effect. If and to the extent language
                in this Addendum or any of its Exhibits conflicts with the
                Agreement, this Addendum shall control.
              </li>
            </ol>
            <ol
              type="1"
              id="0e079ae8-2c3e-466c-9e2d-005f3ea48ec6"
              class="numbered-list"
              start="4"
            >
              <li>
                c) Duration and Survival. This Addendum will become legally
                binding upon the Effective Date of the Agreement or, if later,
                upon the date that Customer electronically accepts, agrees or
                opts-in to this Addendum, and will terminate upon the later to
                occur of (i) termination by either party of the Agreement
                pursuant to the terms thereof, and (b) the Company ceasing to
                Processes Customer Personal Data. Notwithstanding the foregoing,
                any termination of this Addendum shall be without prejudice to
                the survival of accrued rights and liabilities of the parties
                and any obligations of the parties with either expressly or by
                implications survive termination.
              </li>
            </ol>
            <ol
              type="1"
              id="0bdeab0c-8f67-4e92-a564-84aa3aec679d"
              class="numbered-list"
              start="5"
            >
              <li>
                <strong>Definitions</strong>
              </li>
            </ol>
            <p id="32bea9bb-7966-4784-a38b-9ea49da25744" class="">
              For the purposes of this Addendum, the following terms and those
              defined within the body of this Addendum apply.
            </p>
            <ol
              type="1"
              id="1cc93a57-7130-4119-b711-51448f962af5"
              class="numbered-list"
              start="1"
            >
              <li>
                a) &quot;Applicable Data Protection Law(s)&quot; means the
                relevant data protection and data privacy laws, rules and
                regulations to which the Customer Personal Data are subject.
                &quot;Applicable Data Protections Law(s)&quot; shall include,
                but not be limited to, the EU General Data Protection Regulation
                2016/679 (&quot;GDPR&quot;) and Privacy Shield principles and
                requirements.
              </li>
            </ol>
            <ol
              type="1"
              id="e909db54-c791-4478-b9b1-6808e19849cc"
              class="numbered-list"
              start="2"
            >
              <li>
                b) &quot;Customer Personal Data&quot; means Personal Data
                pertaining to Customer&#x27;s end users or employees located in
                the European Economic Area that is Processed by Company. The
                Customer Personal Data and the specific uses of the Customer
                Personal Data are detailed in Exhibit A attached hereto, as
                required by the GDPR.
              </li>
            </ol>
            <ol
              type="1"
              id="82b04542-5aad-4341-bf49-28bab3e841e3"
              class="numbered-list"
              start="3"
            >
              <li>
                c) &quot;Controller&quot; means the natural or legal person,
                public authority, agency or other body which, alone or jointly
                with others, determines the purposes and means of the Processing
                of Personal Data.
              </li>
            </ol>
            <ol
              type="1"
              id="68a8ad61-d7fb-4480-8d1c-ec4244ebf6f4"
              class="numbered-list"
              start="4"
            >
              <li>
                d) &quot;DPA Effective Date&quot; means either (i) February 18,
                2022; and (ii) the date on which you accept or otherwise agree
                or opt-in to this Addendum, if that date is after February 18,
                2022.
              </li>
            </ol>
            <ol
              type="1"
              id="85130840-9467-4ff3-8438-cc83f0c21c47"
              class="numbered-list"
              start="5"
            >
              <li>
                e) &quot;Personal Data&quot; shall have the meaning assigned to
                the terms &quot;personal data&quot; or &quot;personal
                information&quot; under the GDPR.
              </li>
            </ol>
            <ol
              type="1"
              id="38f8313d-8fb9-44dc-ba69-7d41c9332059"
              class="numbered-list"
              start="6"
            >
              <li>
                f) &quot;Process&quot; or &quot;Processing&quot; means any
                operation or set of operations which is performed on data or
                sets of data, whether or not by automated means, such as
                collection, recording, organization, structuring, storage,
                adaptation or alteration, retrieval, consultation, use,
                disclosure by transmission, dissemination, or otherwise making
                available, alignment or combination, restriction, erasure, or
                destruction.
              </li>
            </ol>
            <ol
              type="1"
              id="f4a09a47-f41b-4b57-83f4-2af280747e01"
              class="numbered-list"
              start="7"
            >
              <li>
                g) &quot;Processor&quot; means a natural or legal person, public
                authority, agency or other body which Processes Customer
                Personal Data on behalf of Customer subject to this Addendum.
              </li>
            </ol>
            <ol
              type="1"
              id="88b3b29b-d04d-4315-b6de-a8afb8d05b20"
              class="numbered-list"
              start="8"
            >
              <li>
                h) &quot;Security Incident(s)&quot; means the breach of security
                leading to the accidental or unlawful destruction, loss,
                alteration, unauthorized disclosure of, or access to Customer
                Personal Data Processed by Company.
              </li>
            </ol>
            <ol
              type="1"
              id="4b61a8bf-682c-4db8-960a-6fc8c90fbcf5"
              class="numbered-list"
              start="9"
            >
              <li>
                i) &quot;Services&quot; means any and all services that Company
                performs under the Agreement.
              </li>
            </ol>
            <ol
              type="1"
              id="5de548d7-df16-4bbf-8aaf-637d1effcdbd"
              class="numbered-list"
              start="10"
            >
              <li>
                j) &quot;Third Party(ies)&quot; means any person, contractor,
                agent, vendor or third party company appointed by Company - or
                acting on behalf of Processor (i.e., sub-processors) to Process
                Customer Personal Data on behalf of Customer pursuant to this
                Agreement.
              </li>
            </ol>
            <ol
              type="1"
              id="a9d28657-d897-478a-9acb-8b2cbaeb6942"
              class="numbered-list"
              start="11"
            >
              <li>
                <strong>Data Use and Processing</strong>
              </li>
            </ol>
            <ol
              type="1"
              id="e2de4ca8-0787-4a33-97aa-845274acaacb"
              class="numbered-list"
              start="12"
            >
              <li>
                a) Compliance with Laws. Customer shall ensure that it has
                obtained any and all authorizations and lawful bases for
                processing (including verifiable consent where necessary) in
                accordance with Applicable Data Protection Law(s) in order to
                provide Customer Personal Data to Company for Processing.
                Customer Personal Data shall be Processed in compliance with the
                terms of this Addendum and all Applicable Data Protection
                Law(s). Customer, in its use of the Services, will Process
                Personal Data in accordance with all Applicable Data Protection
                Laws. In addition, Customer shall be solely responsible for the
                accuracy, quality and legality of Personal Data provided to
                Company, and the means by which such Personal Data was obtained
                by Customer.
              </li>
            </ol>
            <ol
              type="1"
              id="891585a5-1aad-4f14-9806-263195e65a5a"
              class="numbered-list"
              start="13"
            >
              <li>
                b) Documented Instructions. Company and its Third Parties shall
                Process Customer Personal Data only for the purpose of providing
                the Services as specifically authorized under the Agreement,
                this Addendum, or any applicable Statement of Work, and at all
                times in accordance with the documented instructions of Customer
                (unless contrary to Applicable Data Protection Laws). Unless
                legally prohibited from doing so, Company will inform Customer
                in writing if it reasonably believes that there is a conflict
                between Customer&#x27;s instructions and Applicable Data
                Protection Laws or if any Third Party seeks to Process Customer
                Personal Data in a manner that is inconsistent with
                Customer&#x27;s instructions.
              </li>
            </ol>
            <ol
              type="1"
              id="93636517-e6f7-44e5-9780-e3373763ed3b"
              class="numbered-list"
              start="14"
            >
              <li>
                c) Authorization to Use Third Parties. To the extent necessary
                to fulfill Company&#x27;s contractual obligations under the
                Agreement or any Statement of Work, Customer hereby authorizes
                (i) Company to engage Third Parties and (ii) Third Parties to
                engage sub-processors. Any Third Party Processing of Customer
                Personal Data shall be consistent with Customer&#x27;s
                documented instructions and shall comply with all Applicable
                Data Protection Laws.
              </li>
            </ol>
            <ol
              type="1"
              id="7b43ea80-fd34-4354-9f27-43e4fa016954"
              class="numbered-list"
              start="15"
            >
              <li>
                d) Company and Third Party Compliance. Company agrees to enter
                into a written agreement with any Third Parties it appoints to
                Process Customer Personal Data that imposes on such Third
                Parties (and their sub-processors) data protection and security
                requirements for Customer Personal Data that are not less
                protective than those set forth in the Agreement and/or this
                Addendum with respect to the protection of Customer Personal
                Data, to the extent applicable to the nature of the services
                provided by such Third Party. Company shall be liable for the
                acts and omissions of Third Parties appointed by Company to the
                same extent Company would be liable if it were performing such
                services directly under the terms of this Addendum. Company will
                provide copies of any agreements with Third Parties that
                Customer may reasonably request from time-to-time, subject to
                redaction of commercial terms and other proprietary information.
              </li>
            </ol>
            <ol
              type="1"
              id="7213c049-c29d-42f1-85c4-2b4b3a7c7708"
              class="numbered-list"
              start="16"
            >
              <li>
                e) Right to Object to Third Parties. Prior to engaging any new
                Third Parties that Process Customer Personal Data, Company will
                notify Customer of these changes by posting its proposed new
                Third Parties to the following
                <Link to="/">&nbsp;website</Link>. Company will allow Customer
                ten (10) calendar days to object after notice is given. It is
                Customer&#x27;s responsibility to check this website regularly
                for updates. If Customer has legitimate objections to the
                appointment of any new Third Party that relates to
                Company&#x27;s compliance with this Addendum, Company will make
                reasonable efforts to address Customer&#x27;s objection. After
                this process, if a resolution has not been agreed to within five
                (5) calendar days, Company will proceed with engaging the Third
                Party. Failing any such resolution, Customer may terminate the
                part of the service performed under the Agreement that cannot be
                performed by Company without use of the objectionable Third
                Party. No refunds shall be given for any prepaid portion of the
                Services.
              </li>
            </ol>
            <ol
              type="1"
              id="214904c0-992d-47db-b76a-0dd6888bfe09"
              class="numbered-list"
              start="17"
            >
              <li>
                f) Confidentiality. Any person or Third Party authorized to
                Process Customer Personal Data must agree to maintain the
                confidentiality of such information or be under an appropriate
                statutory or contractual obligation of confidentiality.
              </li>
            </ol>
            <ol
              type="1"
              id="54b0ec76-9e99-480e-a86f-0108469017cf"
              class="numbered-list"
              start="18"
            >
              <li>
                g) Personal Data Inquiries and Requests. Company agrees to
                promptly comply with all reasonable instructions from Customer
                related to any requests from individuals exercising their rights
                in Personal Data granted to them under Applicable Data
                Protection Law(s) (&quot;Privacy Request&quot;). At
                Customer&#x27;s request, Company agrees to assist Customer in
                answering or complying with any Privacy Request in so far as it
                is possible. Company may invoice Customer for costs arising from
                such assistance.
              </li>
            </ol>
            <ol
              type="1"
              id="9229d1b4-e15a-4779-b01e-8112b09eefc6"
              class="numbered-list"
              start="19"
            >
              <li>
                h) Data Protection Impact Assessment and Prior Consultation.
                Company agrees to provide reasonable assistance at
                Customer&#x27;s sole expense to Customer where, in
                Customer&#x27;s judgement, the type of Processing performed by
                Company is likely to result in a high risk to the rights and
                freedoms of natural persons (e.g., systematic and extensive
                profiling, Processing sensitive Personal Data on a large scale
                and systematic monitoring on a large scale, or where the
                Processing uses new technologies) and thus requires a data
                protection impact assessment and/or prior consultation with the
                relevant data protection authorities.
              </li>
            </ol>
            <ol
              type="1"
              id="da31ee27-91dc-49db-afcf-40ca64a54899"
              class="numbered-list"
              start="20"
            >
              <li>
                i) Demonstrable Compliance. Company agrees to keep records of
                its Processing in compliance with Applicable Data Protection
                Law(s) and provide any necessary records to Customer to
                demonstrate compliance upon reasonable request.
              </li>
            </ol>
            <ol
              type="1"
              id="1e1896a2-76fb-4199-80be-fb539c8782f2"
              class="numbered-list"
              start="21"
            >
              <li>Cross-Border Transfers of Personal Data</li>
            </ol>
            <p>
              Cross-Border Transfers of Personal Data. Customer authorizes
              Company and its Third Parties to transfer Customer Personal Data
              across international borders, including without limitation from
              the European Economic Area to the United States. Any cross-border
              transfer of Customer Personal Data must be supported by an
              approved adequacy mechanism.
            </p>
            <ol
              type="1"
              id="f6570019-0673-446e-86ba-cb0b528ac1e1"
              class="numbered-list"
              start="1"
            >
              <li>Information Security Program</li>
            </ol>
            <ol
              type="1"
              id="b2d95fa0-f601-459a-afb4-f1af8619842b"
              class="numbered-list"
              start="2"
            >
              <li>
                a) Information Security Program. Taking into account the state
                of the art, the costs of implementation and the nature, scope,
                context and purposes of Processing as well as the risk of
                varying likelihood and severity for the rights and freedoms of
                natural persons, Company shall implement and maintain
                appropriate technical and organizational measures set forth in
                Exhibit A hereto in such a manner that its Processing of
                Personal Data will meet the requirements of Applicable Data
                Protection Law(s), ensure the protection of the rights of the
                data subjects, and ensure a level of security appropriate to the
                risk (an &quot;Information Security Program&quot;).
              </li>
            </ol>
            <ol
              type="1"
              id="726ae46b-a9d7-40e7-ba60-67a445a84c29"
              class="numbered-list"
              start="3"
            >
              <li>Security Incidents</li>
            </ol>
            <ol
              type="1"
              id="aca4cd5b-2cc8-4393-b533-7f2632d15d47"
              class="numbered-list"
              start="4"
            >
              <li>
                a) Security Incident Procedure. Upon becoming aware of a
                Security Incident, Company shall use commercially reasonable
                efforts to notifu Customer and provide written details of the
                Security Incident within forty eight (48) hours with the
                sufficient level of detail reasonably required to fulfill
                Customer&#x27;s Security Incident reporting obligations under
                Applicable Data Protection Law(s). Where possible, such details
                shall include, the nature of the Security Incident, the
                categories and approximate number of data subjects concerned and
                the categories and approximate number of Customer Personal Data
                records concerned, the likely consequences of the Security
                Incident, and the measures taken or proposed to be taken to
                mitigate the Security Incident&#x27;s possible adverse effects.
              </li>
            </ol>
            <ol type="1" start="5">
              <li>Audits</li>
            </ol>
            <ol type="1" start="6">
              <li>
                a) Audits. If Applicable Data Protection Law affords Customer an
                audit right, Customer (or its appointed representative) may, no
                more than once annually (or more frequently if required by
                Applicable Data Protection Laws), carry out an inspection of
                Company&#x27;s operations and facilities for the purpose of
                verifying that Company is processing Personal Data in accordance
                with its obligations hereunder. Notwithstanding the foregoing,
                such audit shall consist solely of: (i) the provision by Company
                of written information (including, without limitation,
                questionnaires and information about security policies) that may
                include information relating to Third Parties; and (ii)
                interviews with Company IT employees and subcontractors. Such
                audit may be carried out by Customer or an inspection body
                composed of independent members and in possession of the
                required professional qualifications bound by a duty of
                confidentiality. For the avoidance of doubt no access to any
                part of Company’s IT system, data hosting sites or centers, or
                infrastructure will be permitted. Customer must provide Company
                no less than forty-five (45) days written notice of such
                intention to audit (or such shorter period if required by
                Applicable Data Protection Law), conduct its audit during normal
                business hours, and take reasonable measures necessary to
                prevent unnecessary disruption to Company&#x27;s operations.
                Prior to any audit being conducted, the Parties will agree Any
                such audit shall be subject to Company&#x27;s security and
                confidentiality terms and guidelines. Customer shall be
                responsible for all costs and expenses arising from such audit,
                including the reasonable costs and expenses of Company in
                complying with any such audit request.
              </li>
            </ol>
            <ol type="1" start="7">
              <li>Data Deletion</li>
            </ol>
            <ol type="1" start="8">
              <li>
                a) Data Deletion. At the expiry of termination of the Agreement,
                Company will, at Customer&#x27;s option, delete or return all
                Customer Personal Data to Customer, except where Company is
                required to retain copies under applicable laws, in which case
                Company will isolate and protect that Customer Personal Data
                from any further Processing except to the extent required by
                applicable laws.
              </li>
            </ol>
            <p>
              <strong>Exhibit A</strong>
            </p>
            <p>Technical and Organizational Data Security Measures</p>
            <p>
              The purpose of this document is to describe the principles of the
              technical and organizational data security measures of the
              Company, which Company provides for all Customers as required by
              the Regulation (EU 2016/679), the General Data Protection
              Regulation (&quot;GDPR&quot;). Specific Services level security
              descriptions are available upon request if not otherwise set forth
              herein. Customer specific security measures shall be subject to a
              separate agreement between the parties.
            </p>
            <p>
              Company shall take the following technical and organizational
              security measures to protect Customer Personal Data:
            </p>
            <p>
              Organizational management and dedicated staff responsible for the
              development, implementation, and maintenance of Company’s
              information security program.
            </p>
            <p>
              Audit and risk assessment procedures for the purposes of periodic
              review and assessment of risks to the Company organization,
              monitoring and maintaining compliance with Company policies and
              procedures, and reporting the condition of its information
              security and compliance to senior internal management.
            </p>
            <p>
              Maintain Information security policies and make sure that policies
              and measures are regularly reviewed and where necessary, improve
              them.
            </p>
            <p>
              Communication with Company applications utilizes cryptographic
              protocols such as TLS to protect information in transit over
              public networks. At the network edge, stateful firewalls, web
              application firewalls, and DDoS protection are used to filter
              attacks. Within the internal network, applications follow a
              multi-tiered model which provides the ability to apply security
              controls between each layer.
            </p>
            <p>
              Data security controls which include logical segregation of data,
              restricted (e.g. role-based) access and monitoring, and where
              applicable, utilization of commercially available and
              industry-standard encryption technologies.
            </p>
            <p>
              Logical access controls designed to manage electronic access to
              data and system functionality based on authority levels and job
              functions, (e.g. granting access on a need-to-know and least
              privilege basis, use of unique IDs and passwords for all users,
              periodic review and revoking/changing access promptly when
              employment terminates or changes in job functions occur).
            </p>
            <p>
              Password controls designed to manage and control password
              strength, and usage including prohibiting users from sharing
              passwords.
            </p>
            <p>
              System audit or event logging and related monitoring procedures to
              proactively record user access and system activity for routine
              review.
            </p>
            <p>
              Physical and environmental security of data center, server room
              facilities and other areas containing client confidential
              information (if any) designed to: (i) protect information assets
              from unauthorized physical access, (ii) manage, monitor and log
              movement of persons into and out of Company facilities, and (iii)
              guard against environmental hazards such as heat, fire and water
              damage.
            </p>
            <p>
              Operational procedures and controls to provide for configuration,
              monitoring, and maintenance of technology and information systems
              according to prescribed internal and adopted industry standards,
              including secure disposal of systems and media to render all
              information or data contained therein as undecipherable or
              unrecoverable prior to final disposal or release from Company
              possession.
            </p>
            <p>
              Change management procedures and tracking mechanisms to designed
              to test, approve and monitor all changes to Company technology and
              information assets.
            </p>
            <p>
              Incident / problem management procedures to enable Company to
              investigate, respond to, mitigate and notify of events related to
              Company technology and information assets.
            </p>
            <p>
              Network security controls that provide for the use of enterprise
              firewalls and layered DMZ architectures, and intrusion detection
              systems and other traffic and event correlation procedures
              designed to protect systems from intrusion and limit the scope of
              any successful attack.
            </p>
            <p>
              Vulnerability assessment, patch management, and threat protection
              technologies and scheduled monitoring procedures designed to
              identify, assess, mitigate and protect against identified security
              threats, viruses and other malicious code.
            </p>
            <p>
              Business resiliency/continuity and disaster recovery procedures,
              as appropriate, designed to maintain service and/or recovery from
              foreseeable emergency situations or disasters.
            </p>
            <p>
              <strong>Exhibit B – Details of Processing</strong>
            </p>
            <p>
              Subject Matter of Processing: The provision of the Service to the
              Customer, and related technical support.
            </p>
            <p>
              Duration of the Processing: The Processing will continue until the
              expiration or termination of the Agreement.
            </p>
            <p>
              Nature and Purpose of the Processing: Company will process
              Personal Data submitted to, stored on, or sent via the Service for
              the purpose of providing the Service and related technical support
              in accordance with this Addendum.
            </p>
            <p>
              Types of Customer Personal Data: Personal data submitted to,
              stored on, or sent via the Service may include, without
              limitation, the following categories of data: IP addresses,
              browser agents, email addresses, usernames, full names, browser
              and operating system identifiers, and any other personal data that
              Customer chooses to send us related during the course of our
              provision of the Service and technical support.
            </p>
            <p>
              Categories of Data Subjects: Personal data submitted, stored, sent
              or received via the Service may concern the following categories
              of data subjects, without limitation: Customer&#x27;s employees,
              contractors, and agents; the personnel of Customer&#x27;s
              customers, suppliers and subcontractors; and any other person who
              transmits data via the Service.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DPA
